:root {
  --popup-color: #d4edda;
  --popup-color-error: #e32b24;
  --danger-color: #e32b24;
  --warning-color: #ffc107;
  --severe-color: #fd7e14;
}

html {
  height: 100%;
}

body {
  height: 100%;
  padding: 10px;
  padding-top: 90px;
  font-family: Arial, Helvetica, sans-serif;
}

.container-fluid {
  overflow-y: auto;
  overflow-x: auto;
}

.navbar {
  margin-bottom: 0;
}

div > h1 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 0px;
  font-size: 1.5em;
}

div > h2 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.2em;
}

h2,
h3 {
  color: #444;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

.navbar-fixed-top .nav {
  padding: 15px 0;
}

@media (max-width: 767px) {
  body {
    padding: 4px;
    padding-top: 65px;
  }
  .navbar-brand {
    padding: 0;
  }
  .navbar-brand img {
    margin-top: 5px;
    margin-left: 5px;
  }
}

.loading {
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  z-index: 999;
  background: url('./assets/images/loading.gif') no-repeat;
}

.row.required .col-form-label:after {
  content: '*';
  color: orangered;
}

.pad-bottom {
  margin-bottom: 10px;
}

.pad-left {
  margin-left: 10px;
}

#pageNumber {
  width: 40px;
}

.rag-blue {
  background-color: lightsteelblue;
  color: white;
}

.steam-highlight {
  background-color: lightsteelblue;
  color: white;
}

.full-span {
  height: 100%;
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: '';
  clear: both;
  height: 0;
}

* html .clearfix {
  zoom: 1;
}

*:first-child + html .clearfix {
  zoom: 1;
}

.form-group.required .control-label:after {
  color: #d00;
  content: '*';
  position: absolute;
  margin-left: 5px;
  top: 7px;
}

.text-nowrap {
  white-space: nowrap;
}

.form-checkbox {
  height: 20px;
  width: 20px;
  margin-top: 2px !important;
}

.button-top {
  margin-bottom: 12px;
}

.wrap {
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap;
  /*Chrome & Safari */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: pre-wrap;
  /* css-3 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}

.userRow {
  padding: 8px 0px;
  border-top: 1px solid #ddd;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.userRow .assign-icon {
  display: table-cell;
  width: 40px;
}

.userRow button {
  margin-right: 8px;
}

.userRow .text {
  padding-left: 4px;
  text-align: left;
  vertical-align: middle;
  display: table-cell;
}

.userRow .user {
  display: table-cell;
  height: 32px;
  width: 32px;
  top: 4px;
  padding-left: 40px;
  background: url('assets/images/user.svg') no-repeat top left;
}

.userRow .userAdmin {
  display: table-cell;
  height: 32px;
  width: 32px;
  top: 4px;
  padding-left: 40px;
  background: url('assets/images/user_admin.svg') no-repeat top left;
}

.userRow .userGa {
  display: table-cell;
  height: 32px;
  width: 32px;
  top: 4px;
  padding-left: 40px;
  background: url('assets/images/user_ga.svg') no-repeat top left;
}

.userRow .userDisabled {
  display: table-cell;
  height: 32px;
  width: 32px;
  top: 4px;
  padding-left: 40px;
  background: url('assets/images/user_disabled.svg') no-repeat top left;
}

.userRow .userUnused {
  display: table-cell;
  height: 32px;
  width: 32px;
  top: 4px;
  padding-left: 40px;
  background: url('assets/images/user_unused.svg') no-repeat top left;
}

.userRow .userOverride {
  display: table-cell;
  height: 32px;
  width: 32px;
  top: 4px;
  padding-left: 40px;
  background: url('assets/images/user_override.svg') no-repeat top left;
}

.product {
  margin: 0 0 2em 0;
  list-style-type: none;
  padding-left: 0;
  width: 24em;
}

.product li {
  font-weight: bold;
  left: 0;
}

.product .text {
  position: relative;
  top: -4px;
}

.feature {
  margin: 0 0 2em 0;
  list-style-type: none;
  padding: 0;
  width: 24em;
}

.feature li {
  font-weight: normal;
  left: 0px;
  padding-left: 10px;
}

.feature .text {
  position: relative;
  top: -4px;
}

.delete {
  color: firebrick;
}

.ml6 {
  margin-left: 6px;
}

.ml10 {
  margin-left: 10px;
}

.mr10 {
  margin-right: 10px;
}

.pointer {
  cursor: pointer;
}

.popup-info {
  --mdc-snackbar-container-color: var(--popup-color) !important;
  --mdc-snackbar-supporting-text-color: black !important;
}

.popup-error {
  --mdc-snackbar-container-color: var(--popup-color-error) !important;
  --mdc-snackbar-supporting-text-color: whitesmoke !important;
}

simple-snack-bar {
  text-align: center;
}

.primary-menu fa-icon {
  margin-right: 4px;
}

.primary-menu a {
  white-space: nowrap;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.btn-light {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

@media (min-width: 576px) {
  .e2g-label-align {
    text-align: end;
  }
}

.danger {
  color: var(--danger-color);
}

.cdk-overlay-container {
  z-index: 10000 !important;
}

cdk-virtual-scroll-viewport {
  height: 100%;
}

.flex-111 {
  flex: 1 1 1px;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-bg-type: #f9f9f9 !important;
}

.e2g-padding {
  padding: 20px 25px 10px 25px;
}

.container-padding {
  padding: 0 15px;
}

.license-label {
  margin: 5px;
}

.license-slot {
  display: flex;
  padding: 0;
  position: relative;
  left: 0;
  margin: 0.5em;
  height: 23px;
  border-radius: 4px;
}

.license-slot .text {
  flex-grow: 1;
  height: 23px;
  padding-left: 8px;
  background-color: #eee;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 0 4px 4px 0;
}

.license-slot .badge {
  font-size: small;
  color: white;
  padding: 0.4em 0.7em 0 0.7em;
  background-color: #607d8b;
  line-height: 1em;
  height: 23px;
  width: 40px;
  border-radius: 4px 0 0 4px;
  text-align: center;
  overflow: hidden;
}

ag-grid-angular {
  width: 100%;
  height: 100%;
}

.control-container {
  border-top: solid 1px var(--e2g-lightgrey);
}

.color-group {
  padding-top: 10px;
  padding-bottom: 10px;
  background: cornsilk!important;
}

.color-group2 {
  padding-top: 10px;
  padding-bottom: 10px;
  background: seashell!important;
}

app-date-picker {
  width: 100%;
}

/** Table Header Fixed **/
.table-header-fixed thead th {
  z-index: 100;
  position: sticky;
  top: 0;
  border: none;
}

.table-header-fixed table {
  border-collapse: collapse;
  width: 100%;
}
.table-header-fixed th,
.table-header-fixed td {
  padding: 8px 16px;
}
